.swagger-ui{
	
	//Add any API calls here that don't require the acess token header
	#operations-story-get-story, 
	#operations-experience-get-used-experiences,
	#operations-experience-get-vip-experiences,
	#operations-experience-get-experience,
	#operations-experience-create-experience,
	#operations-experience-create-and-render-experience,
	#operations-experience-create-or-retrieve-rendered-experience{

		.authorization__btn{
			
			svg{
				fill:#49cc90;
			}
		}
	}

	.opblock-summary-control,
	.models-control,
	.model-box-control {
		&:focus {
			outline: 0 auto;
		}
	}

	.opblock-tag-section{
		box-sizing:border-box;
		border:1px solid @bgDark;
		border-radius:5px;
		margin-bottom:10px;

		&.is-open{
			border:none;
			border:1px solid @bgMedium;
		}
	}

	.opblock-tag{

		small{
			color:@subtleGrey;
			text-transform: initial;
			font-size:12px;
			padding-top: 12px;
		}
	}
	
	.btn{

		&.json-schema-form-item-add {
			margin-top: 5px;
		}

		&.json-schema-form-item-remove {
			margin-left: 5px;
		}

		&.execute, &.json-schema-form-item-add, &.json-schema-form-item-remove {
			background:white;
			color:@bgVeryDark;
			border:none;
		}
		&.btn-clear, &.body-param__example-edit {
			border:2px solid white;
			color:white;
		}
	}

	textarea{

		&.curl, &.body-param__text{
			background:@bgVeryDark;
			color:@codeGreen;
			font-weight:400
		}
	}

	.responses-inner{
		h4, h5{
			color:@titleWhite;
		}
	}

	.parameters-col_description, .parameter__type, .parameters-col_name, .response-col_description, .response-col_status{
		color:@textGrey;

		input[type=text]{
			font-family:monospace;
			font-size:12px;
			.pop;
			background:@bgLight;
		}
	}
	
	.parameter__in{
		color:@subtleGrey;
	}

	.parameter__name{
		color:@titleWhite;
		
		span{
			display: none;
		}

		&.required{
			&:after{
				color: @titleWhite;
				background: @deleteColor;
				padding: 1px;
				border-radius: 2px;
				display: inline-block;
				top: -3px;
				left: 5px;
				font-size: 10px;
				padding: 2px 5px 2px 5px;
			}
		}
	}
	
	.response-col_description{
		div{
			&.markdown{
				background:@bgVeryDark;
			}
		}
	}

	.response-col_description__inner{
		div{
			&.renderedMarkdown{
				font-family:monospace;
				color:@codeGreen;
				background:@bgVeryDark;
				padding:10px;
				border-radius:4px;
				p{
					color:@codeGreen;
					font-weight:400;
					margin:0;
				}
			}
		}
	}
	
	.opblock-body{
		a {
			color: @linkBlue;

			&:visited {
				color: @linkBlue;
			}
		}
		pre{
			background:@bgVeryDark !important;
			color:@codeGreen !important;
			font-weight:400 !important;

			span{
				color:@codeGreen !important;
			}
		}

		table {
			thead {
				.col_header {
					font-weight: 800;
					font-size: 14px;
				}
			}

			tbody {
				tr {
					&:first-child {
						td {
							&.response-col_description, &.response-col_status {
								padding: 12px 0 0;
							}
						}
					}

					td {
						&.response-col_description, &.response-col_status {
							padding: 50px 0 0;
						}

						&.response-col_status {
							&:first-of-type {
								width: 75px;
								min-width: 0;
								max-width: 0;
								font-weight: 800;
								line-height: 22px;

								.response-undocumented {
									font-size: 8px;
								}
							}
						}
					}
				}
			}
		}
	}

	.opblock-description-wrapper p{
		color:@textGrey;
	}

	.opblock-description-wrapper ul li{
		color:@textGrey;
		font-size:14px;
		margin-left:1.8em;
	}
	
	.tab{
		li{
			color:@titleWhite;
		}
	}

	.opblock-tag{
		color:@titleWhite;
		text-transform:uppercase;
		.oswaldBold;
		letter-spacing: 1px;
	}

	.opblock{
		
		.opblock-section-header{
			background:#3a3a3a;

			h4{
				color:@titleWhite;
			}
		}

		.opblock-summary-path{
			color:@titleWhite;
		}

		.opblock-summary-description{
			color:@subtleGrey;
		}
		
		&.opblock-get{
			.style-block(@getColor);
		}

		&.opblock-post{
			.style-block(@postColor);
		}

		&.opblock-delete{
			.style-block(@deleteColor);
		}

		&.opblock-patch{
			.style-block(@patchColor);
		}

		&.opblock-put{
			.style-block(@putColor);
		}
	}
}

.style-block(@color){
	
	.pop;

	.opblock-summary{
		border:none;
	}

	.opblock-summary-method{
		.oswaldReg;
		font-size:18px;
		letter-spacing:1px;
		background:@color;
	}
	
	.try-out__btn{
		background:none;
		border:2px solid white;
		color:white;
	}

	.tab-header{

		.tab-item{
			&.active{
				h4{
					span{
						&:after{
							background:@color;
						}
					}
				}
			}
		}
	}
}
