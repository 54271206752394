.pop{
	border-left: 1px solid #2d2d2d;
	border-right: 1px solid #2d2d2d;
	border-top: 1px solid #272727;
	border-bottom: 1px solid #555;
	background-color: @bgDark;
	color:@textGrey;
	box-shadow:none;
}

.oswaldBold{
	font-family:'Oswald', sans-serif;
	font-weight:600;
}

.oswaldReg{
	font-family:'Oswald', sans-serif;
	font-weight:400;
}
