
.swagger-ui{
	
	.model-title{
		color:@titleWhite;
	}
	
	.model{
		color:@textGrey;
		font-weight:400;
	}

	.prop-type{
		color:@codeGreen;
		font-weight:600;
	}
	
	.model-toggle{
		&:after{
			background:url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' fill='%23ffffff'/></svg>") 50% no-repeat;
		}
	}
	section{

		&.models{
			
			border:1px solid @bgDark;

			&.is-open{
				border:none;
			}
			h4{
				color:@titleWhite;
			}

			.model-container{
				.pop;
				&:hover{
					background:@bgDark;
				}
			}
		}
	}
}