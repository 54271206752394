
.swagger-ui{

	.info{
		
		.doc-info{
			background:@bgDark;
			padding:10px 30px 20px 30px;
			box-sizing: border-box;
			border-left: 1px solid #2d2d2d;
			border-right: 1px solid #2d2d2d;
			border-top: 1px solid #272727;
			border-bottom: 1px solid #555;
			border-radius: 4px;

			section{
				margin-bottom:30px;
				padding-bottom:15px;
				border-bottom:5px solid @bgLight;

				&:last-child {
					border-bottom: 0 none;
					margin-bottom: 0;
				}
			}
			h1{
				color:white;
				.oswaldReg;
				text-transform:uppercase;
			}

			h2{
				color:#f5f5f5;
				.oswaldReg;
				text-transform:uppercase;
				margin-bottom:15px;
				margin-top:35px;
				font-size:24px;

				a{
					.oswaldReg;
					font-size:18px;

					.route{
						text-transform: lowercase;
					}
				}
			}

			h3{
				color:@textGrey;
				.oswaldReg;
				text-transform: uppercase;
				margin-top: 40px;
				margin-bottom: 10px;
				font-size: 18px;

				a{
					.oswaldReg;
					text-transform: uppercase;
					font-size: 18px;			
				}
			}

			p{
				color:@textGrey;
				margin: 0 0 15px 0;
				font-size:15px;
				max-width:800px;
				line-height: 1.3;
			}

			.header{
				font-family:monospace;
				display: inline-block;
				padding:1px 4px 1px 4px;
				background:#2a2a2a;
				border-radius:2px;
			}

			.key{
				color:white;
				font-weight:bold;
			}

			.version{
				color:white;
				display: inline-block;
				padding:1px 4px 1px 4px;
				background:#7d8492;
				border-radius:2px;	
			}

			pre{
				background: @bgVeryDark;
			    color: #9db493;
				margin: 0;
			    font-weight: 400;
			    font-size:12px;
			    padding:10px;
			    max-width:750px;
			}

			.lock-icon{
				display: inline-block;
				margin-bottom: -4px;
				fill: #49cc90;
			}

			.highlight{
				font-weight:bold;	
			}
		}
	}
}
