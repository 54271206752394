.swagger-ui{
	
	//INFO
	.info{

		.title{
			color:white;
			.oswaldReg;
		}

		p{
			color:@textGrey;
		}

		a{
			color:@linkBlue;
		}
	}
}