@borderStyle: 1px solid @bgDark;

.auth-wrapper {
    .dialog-ux {
        .backdrop-ux {

        }

        .modal-ux {
            background: @bgMedium;
            border: @borderStyle;

            .modal-dialog-ux {
                .modal-ux-inner {
                    .modal-ux-header {
                        border: 0;
                        border-bottom: @borderStyle;

                        h3 {
                            color: @titleWhite;
                        }

                        svg {
                            fill: @titleWhite;
                        }
                    }

                    .modal-ux-content {
                        max-height: 800px;
                        .auth-container {
                            border-bottom: 0;

                            form {
                                h4, p, label {
                                    color: @textGrey;
                                }

                                input[type=text] {
                                    .pop;
                                    font-family:monospace;
                                    font-size:12px;
                                    background:@bgLight;
                                }

                                .auth-btn-wrapper {
                                    justify-content: flex-start;

                                    button {
                                        border: 2px solid white;
                                        color: white;

                                        &:first-child {
                                            margin-right: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
