@import "reset.less";
@import "colors.less";
@import "mixins.less";
@import "opblock.less";
@import "scheme.less";
@import "info.less";
@import "models.less";
@import "auth.less";
@import "doc-info.less";
@import "header.less";

//GLOBAL OVERRIDES
html {
    box-sizing: border-box;
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    margin: 0;
    padding-top:60px;
    background: @bgMedium;
    -webkit-font-smoothing: antialiased;
}

@docWidth:1280px;

//STYLES
.swagger-ui{

	color:@textGrey;

	//GLOBALS
	svg{
		fill:@titleWhite;
	}

	select{
		.pop;
	}

	.wrapper{
		max-width:@docWidth;
	}
	
	.info__contact{
		display: none;
	}

	.info__extdocs{
		display: none;
	}
	
	//HIDDEN UI
	.topbar, .response-col_links, .response-content-type {
    	display: none;
	}

	.loading-container{

		.loading{

			&:after{
				color:white;
			}

			&:before{
				border:2px solid #666;
				border-top-color:white;
			}
		}
	}
}
