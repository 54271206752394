
.swagger-ui{
	
	.scheme-container{
		background:@bgLight;

		.servers-title{
			color:@titleWhite;
		}
	}
}